import { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { LoaderDots } from '@xemplo/loader';

import { Button } from './button';
import { LoaderSizeMap } from './button.helpers';
import { ButtonSize, ButtonWithStateProps } from './button.types';

export const ButtonWithState = (props: PropsWithChildren<ButtonWithStateProps>) => {
  const [loading, setLoading] = useState(props.isLoading ?? false);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    onClickAsync,
    onClick,
    disabled,
    icon,
    children,
    size = ButtonSize.Standard,
  } = props;

  // Create a new function that wraps the original `onClickAsync`
  const handleOnClickCallback = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        if (ref.current) {
          ref.current.style.minWidth = `${ref.current.offsetWidth}px`;
        }
        setLoading(true); // Set the loading state to true
        onClick?.(e); // call the original `onClick` function
        await onClickAsync?.(e); // call the original `onClickAsync` function
      } finally {
        // Set the loading state when the operation completes regardless the result
        setLoading(false);
      }
    },
    [onClick, onClickAsync]
  );

  return (
    <Button
      ref={ref}
      {...props}
      disabled={loading || disabled}
      icon={loading ? null : icon}
      onClick={handleOnClickCallback}
    >
      {loading ? <LoaderDots baseSize={LoaderSizeMap[size]} /> : children}
    </Button>
  );
};
export default ButtonWithState;
