import { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import * as S from './icon-button.styles';
import { IconButtonProps, IconButtonSize, IconButtonVariant } from './icon-button.types';

/** The size map, the first number represents the font-size, the second the button heigh/width */
const SizeMap = new Map([
  [IconButtonSize.XSmall, [12, 20]],
  [IconButtonSize.Small, [16, 32]],
  [IconButtonSize.Medium, [24, 40]],
  [IconButtonSize.Standard, [32, 48]],
  [IconButtonSize.Large, [40, 64]],
]);

export const IconButtonTestId = {
  Button: (id: string) => `icon-button-${id}`,
};

export const IconButton = forwardRef(
  (props: PropsWithChildren<IconButtonProps>, ref?: React.Ref<HTMLButtonElement>) => {
    const { naked, ariaLabel, className, ...rest } = props;
    // Stupid typescript doesn't know that the default value is set when props.size is undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const size = SizeMap.get(props.size ?? IconButtonSize.Medium)!;
    const variant = props.variant ?? IconButtonVariant.Default;
    return (
      <S.IconButton
        type="button"
        ref={ref}
        $size={size}
        aria-label={ariaLabel}
        className={classNames(className, variant, { naked })}
        {...rest}
        data-testid={IconButtonTestId.Button(props.id)}
      >
        {props.children}
      </S.IconButton>
    );
  }
);

export default IconButton;
