//@ts-nocheck
import env from "./environment";
import { getUserArea, templateEngine } from "../libs/utilities";

class ApiUrlHandler {
  _urls: any;
  constructor() {
    if (!ApiUrlHandler.instance) {
      this._urls = {
        IDENTITY: "/api/callback",
        USER_ROLES_UPDATE_URL: "/api/personaswitch",
        // Company
        COMPANY_URL: "/<%userArea%>/companies",
        COMPANY_LOOKUP_URL: "/<%userArea%>/Lookups/companies",
        COMPANY_USER_URL: "/<%userArea%>//companies/<%id%>/users",
        COMPANY_BUSINESS_UNIT_URL:
          "/<%userArea%>/companies/<%id%>/businessunits",
        COMPANY_INVOICE: "/<%userArea%>/companies/<%id%>/currentInvoices",
        COMPANY_PAYRUNDATA: "/<%userArea%>/companies/<%id%>/monthlyPayRunData",
        CLIENT_EMPLOYEES_URL: "/<%userArea%>/businessunits/<%id%>/clientemployees",
        CLIENT_EMPLOYEES_URL_BY_PAYRUN: "/<%userArea%>/keypay/getEmployeesWithQuery/<%id%>",
        // Business unit url
        BUSINESS_UNIT_URL: "/<%userArea%>/businessunits",
        REPORT_BUSINESS_UNIT_URL: "/<%userArea%>/businessunits/report-enabled",
        BUSINESSUNIT_CURRENCY_FEES_URL:
          "/<%userArea%>/businessunits/<%id%>/currencyfee",

        // BSP URL
        BILLING_PROVIDERS_URL: "/<%userArea%>/bsps",
        BILLING_PROVIDER_DETAIL_URL: "/<%userArea%>/bsps/<%id%>",
        BILLING_ACCOUNT_URL: "/<%userArea%>/bsps/<%id%>/accounts",
        BILLING_ACCOUNT_DETAIL_URL:
          "/<%userArea%>/bsps/<%id%>/accounts/<%accountId%>",
        BILLING_XERO_URL: "/<%userArea%>/bsps/<%id%>/xerocodemapping",
        BILLING_XERO_DETAIL_URL:
          "/<%userArea%>/bsps/<%id%>/xerocodemapping/<%xeroId%>",
        BILLING_PROVIDERS_UPLOAD_LOGO_URL: "/<%userArea%>/bsps/uploadlogo",
        BILLING_PROVIDERS_CONFLICTS_URL:
          "/<%userArea%>/bsps/<%id%>/chartofaccountconflict",
        BILLING_PROVIDERS_CONFLICTS_RESOLVE_URL:
          "/<%userArea%>/bsps/<%id%>/chartofaccountconflict/<%conflictId%>/resolve",

        CURRENCY_URL: "/<%userArea%>/currencies",
        EXTERNAL_PAYSCHEDULE:
          "/<%userArea%>/payruns/<%id%>/externalpayschedules",
        EXTERNAL_BUSINESSUNIT: "/<%userArea%>/payruns/externalbusinessunits",
        PAYROLL_CONFIG_URL:
          "/<%userArea%>/payschedules/<%id%>/paySchedulePayrollSystemConfiguration",
        EXTERNAL_PAYRUN_EARNINGLINES:
          "/<%userArea%>/Payruns/<%id%>/externalPayrunEarningLines",
        EXTERNAL_PAYRUN_EARNINGLINES_POST:
          "/<%userArea%>/Payruns/<%id%>/importPayrunTotalsFromExternalPayrun",
        EXTERNAL_PAYRUN_EARNINGLINES_CLEAR:
          "/<%userArea%>/Payruns/<%id%>/payrunEarningLines/clear",
        EXTERNAL_PAYRUN_URL:
          "/<%userArea%>/Payruns/<%businessId%>/payschedules/<%payscheduleId%>/externalpayruns",
        INVOICE_URL: "/<%userArea%>/payruns/<%id%>/invoices",
        EXPORT_URL: "/<%userArea%>/payruns/<%id%>/invoices/<%invoiceId%>",
        XERO_CALLBACK: "/api/xero/url",

        // Payrun url
        PAYRUN_EDIT_URL: "/<%userArea%>/payruns/<%id%>",
        PAYRUN_CURRENCY_FEES_URL: "/<%userArea%>/payruns/<%id%>/currencyfee",
        EXPORT_ABA: "/<%userArea%>/Payruns/<%id%>/savebankdetails",
        PAYRUN_URL: "/<%userArea%>/payruns",
        PAYRUN_DETAIL_URL: "/<%userArea%>/payruns/<%id%>",
        PAYRUN_REJECT_URL: "/<%userArea%>/Payruns/<%id%>/reject",
        UPDATE_PAYRUN_DATES_URL: "/<%userArea%>/payruns/updatePayrunDates",
        AMENDMENT_URL: "/<%userArea%>/Payruns/<%id%>/amendment",
        AMENDMENT_ACTIONID_URL:
          "/<%userArea%>/Payruns/<%id%>/amendment/<%amendmentId%>/<%action%>",
        AMENDMENT_SUBMIT_URL:
          "/<%userArea%>/Payruns/<%id%>/amendment/approvereject",
        AMENDMENT_APPROVE_URL: '/api/v2/amendments/approve',
        AMENDMENT_REJECT_URL: '/api/v2/amendments/reject',
        AMENDMENT_UPLOAD_URL:
          "/<%userArea%>/Payruns/<%id%>/amendments/<%amendmentId%>/upload",
        AMENDMENT_TASK_URL: "/<%userArea%>/amendmentTaskTypes",
        AMENDMENT_TYPE_URL: "/<%userArea%>/lookups/changeTypes",
        AMENDMENT_TASK_BY_TYPE_URL: "/<%userArea%>/lookups/changeTasks",
        AMENDMENT_TASK_SUB_TYPES_URL: "/<%userArea%>/changeTaskSubTypes",
        PAYRUN_AMENDMENTS_URL: "/<%userArea%>/Payruns/<%id%>/amendments",
        PAYRUN_AMENDMENTS_URL_V2: "/api/v2/amendments",
        PAYRUN_AMENDMENT_KEYPAY_STATUS_URL:
          "/<%userArea%>/Payruns/amendment/<%id%>/keypay",
        PAYRUN_ACTION_URL: "/<%userArea%>/Payruns/<%id%>/<%action%>",
        PAYRUN_COMPLETE: "/<%userArea%>/payrun/completed",
        PAYSCHEDULE_PAYRUN: "/<%userArea%>/PaySchedules/<%id%>/payruns",
        ADD_AMENDMENT: "/<%userArea%>/Payruns/<%id%>/amendment/add",
        ADHOC_PAYRUN: "/<%userArea%>/payruns/createadhocpayrun",
        APPROVE_EARNING: "/<%userArea%>/Payruns/<%id%>/payrunEarningLines",
        EMPLOYEES_NOT_IN_PAYSCHEDULE_URL:
          "/<%userArea%>/Payruns/<%id%>/listmissingexternalemployee",
        IMPORT_MISSING_EMPLOYEES_URL:
          "/<%userArea%>/Payruns/<%id%>/importmissingexternalemployee",
        ATTACH_AMENDMENTS_URL: "/<%userArea%>/Payruns/<%id%>/amendment/attach",
        LINK_EXTERNAL_PAYRUN_URL:
          "/<%userArea%>/Payruns/<%id%>/linkExternalPayRun",
        PAYRUN_CHANGELOG_URL: "/<%userArea%>/payruns/<%id%>/history",
        SKIP_PAYRUNS_URL: "/<%userArea%>/payruns/skippayruns",
        // Upload/Download pay run reports
        UPLOAD_PAYRUN_REPORTS_URL:
          "/<%userArea%>/payruns/<%id%>/upload?displayName=<%displayName%>",
        GET_PAYRUN_REPORTS_URL: "/<%userArea%>/payruns/<%id%>/getFiles",
        DOWNLOAD_PAYRUN_REPORTS_URL:
          "/<%userArea%>/payruns/downloadFile/<%id%>",
        PAYRUN_EMPLOYEE_LIST_URL:
          "/<%userArea%>/keypay/getEmployees/<%payrunId%>",
        PAYRUN_REIMPORT_EARNINGS_URL:
          "/<%userArea%>/payruns/<%payrunId%>/reimportPayrunTotalsFromExternalPayrun",

        // Invoices
        PAYRUN_INVOICES_URL: "/<%userArea%>/Payruns/invoices",
        PAYRUN_AUDIT_REPORT_URL: "/<%userArea%>/Payruns/<%id%>/auditReport",

        // Variance
        VARIANCE_URL: "/<%userArea%>/Payruns/variancereport",

        // Lookup Url
        COUNTRIES_LOOKUP: "/<%userArea%>/Lookups/countries",
        TAX_FEE_URL: "/<%userArea%>/lookups/<%id%>/taxfees",
        STATE_LOOKUP: "/<%userArea%>/Lookups/countries/<%id%>/states",
        EARNINGLINES_LOOKUP: "/<%userArea%>/PayItems",
        TIMEZONE_LOOKUP: "/<%userArea%>/Lookups/operatingtimezone",
        PAYROLL_PROVIDER_LOOKUP:
          "/<%userArea%>/Lookups/payrollserviceproviders",
        PAYROLL_PROVIDER: "/<%userArea%>/payrollserviceproviders",
        PAYROLL_UPLOAD_LOGO_URL:
          "/<%userArea%>/payrollserviceproviders/uploadlogo",
        PAYROLL_CONNECTION_URL:
          "/<%userArea%>/payrollserviceproviders/<%id%>/payrollSystemConnections",
        PAYROLL_CONNECTION_LOOKUP_URL:
          "/<%userArea%>/lookups/<%id%>/payrollSystemConnections",
        PAYROLL_PROVIDER_BUSINESSUNIT:
          "/<%userArea%>/payrollserviceproviders/<%id%>/businessunits",
        PAYSCHEDULE_LOOKUP_URL: "/<%userArea%>/Lookups/payschedules",

        // Payschedule url
        PROCESSINGFEES: "/<%userArea%>/PaySchedules/<%id%>/processingfees",
        PAYSCHEDULE: "/<%userArea%>/PaySchedules",
        PAYSCHEDULE_DETAIL_URL: "/<%userArea%>/PaySchedules/<%id%>",
        PAYSCHEDULE_CURRENCY_FEES_URL:
          "/<%userArea%>/payschedules/<%id%>/currencyfee",
        PAYSCHEDULE_DELETE_URL: "/<%userArea%>/payschedules/<%id%>",

        // Users
        USERS: "/<%userArea%>/users",
        COMPANY_USERS_URL: "/<%userArea%>/users/companyuser",
        COMPANY_USERS_URL_COMPANYUSER: "/<%userArea%>/users",
        PSP_USERS_URL: "/<%userArea%>/users/serviceprovideruser",
        COMPANY_USERS_ACTION_URL:
          "/<%userArea%>/users/companyuser/<%id%>/<%action%>",
        COMPANY_USERS_ACTION_URL_COMPANYUSER:
          "/<%userArea%>/users/<%id%>/<%action%>",
        PSP_USERS_ACTION_URL:
          "/<%userArea%>/users/serviceprovideruser/<%id%>/<%action%>",
        PAYROLL_USERS_DETAIL_URL:
          "/<%userArea%>/payrollserviceproviders/<%pspId%>/users/<%userId%>",
        //Assign/Unassign PM to/from single business unit
        BU_ASSIGN_PM_URL: "/<%userArea%>/businessunits/<%id%>/assignUsers",
        //Assign PM to multiple business units
        PM_ASSIGN_MULTIPLE_BU_URL:
          "/<%userArea%>/users/serviceprovideruser/<%id%>/assignbusinessunit",
        //Unassign PM from multiple business units
        BU_UNASSIGN_USERS_URL:
          "/<%userArea%>/businessunits/unassignUsersByUserId",
        //Unassign All PMs from multiple business unit
        BU_UNASSIGN_ALL_USERS_URL:
          "/<%userArea%>/businessunits/unassignAllUsers",

        COMPANY_USER_REGISTRATION: "/<%userArea%>/users/companyuser/register",
        COMPANY_USER_REGISTRATION_COMPANYUSER: "/<%userArea%>/users/register",
        PSP_USER_REGISTRATION:
          "/<%userArea%>/users/serviceprovideruser/register",
        USER_ACTIVATION_UPDATE_URL: "/<%userArea%>/users/terms",

        // MFA enable/disable
        USER_MULTI_FACTOR_ENABLE_URL: "/<%userArea%>/users/mfa-enable/<%id%>",
        USER_MULTI_FACTOR_DISABLE_URL: "/<%userArea%>/users/mfa-disable/<%id%>",

        // Company User MFA Enable/disable All
        COMPANY_USER_MULTI_FACTOR_ENABLE_URL:
          "/<%userArea%>/users/company/<%id%>/mfa-enable",
        COMPANY_USER_MULTI_FACTOR_DISABLE_URL:
          "/<%userArea%>/users/company/<%id%>/mfa-disable",

        // PSP User MFA Enable/disable All
        PSP_USER_MULTI_FACTOR_ENABLE_URL:
          "/<%userArea%>/users/serviceprovider/<%id%>/mfa-enable",
        PSP_USER_MULTI_FACTOR_DISABLE_URL:
          "/<%userArea%>/users/serviceprovider/<%id%>/mfa-disable",

        // Device Enrolment enable/disable
        USER_DEVICE_ENROLMENT_ENABLE_URL:
          "/<%userArea%>/users/device-enrolment-enable/<%id%>",
        USER_DEVICE_ENROLMENT_DISABLE_URL:
          "/<%userArea%>/users/device-enrolment-disable/<%id%>",

        // Company User MFA Enable/disable All
        COMPANY_USER_DEVICE_ENROLMENT_ENABLE_URL:
          "/<%userArea%>/users/company/<%id%>/device-enrolment-enable",
        COMPANY_USER_DEVICE_ENROLMENT_DISABLE_URL:
          "/<%userArea%>/users/company/<%id%>/device-enrolment-disable",

        // PSP User MFA Enable/disable All
        PSP_USER_DEVICE_ENROLMENT_ENABLE_URL:
          "/<%userArea%>/users/serviceprovider/<%id%>/device-enrolment-enable",
        PSP_USER_DEVICE_ENROLMENT_DISABLE_URL:
          "/<%userArea%>/users/serviceprovider/<%id%>/device-enrolment-disable",

        // Userr roles
        USER_ROLES_URL: "/api/userrole",
        ROLES_LOOKUP_URL: "/<%userArea%>/lookups/roles",
        AIRWALLEX_URL: "/<%userArea%>/businessunits/<%id%>/airwallex",
        EMAIL_TEMPLATES_URL: "/<%userArea%>/users/emailtemplates",
        EMAIL_TEMPLATES_ACTION_URL: "/<%userArea%>/users/emailtemplate/<%id%>",
        PAY_CATEGORY_LOOKUP_URL: "/<%userArea%>/lookups/paycategory",
        EXPENSES_LOOKUP_URL: "/<%userArea%>/lookups/expensecategory",
        CONNECT_KEYPAY_URL:
          "/<%userArea%>/businessunits/<%id%>/externalpaycategory",
        CONNECT_KEYPAY_EXPENSES_URL:
          "/<%userArea%>/businessunits/<%id%>/externalexpensecategory",
        MISSING_EXTERNAL_PAYCATEGORY:
          "/<%userArea%>/payruns/<%id%>/missingexternalpaycategory",
        EXTERNAL_PAY_CATEGORY_LOOKUP_URL:
          "/<%userArea%>/businessunits/getexternalpaycategory",
        ARCHIEVED_EMPLOYEES_URL: "/<%userArea%>/payruns/<%id%>/clientEmployees",
        IMPORT_EMPLOYEE_URL: "/<%userArea%>/payruns/clientEmployees",
        SYNC_EMPLOYEES_FROM_KEYPAY:
          "/<%userArea%>/keypay/importEmployeesByBusinessUnitId/<%id%>",
        SYNC_DEDUCTION_CATEGORIES_FROM_KEYPAY:
          "/<%userArea%>/keypay/importDeductionCategoriesByBusinessUnitId/<%id%>",
        SYNC_PAY_CATEGORIES_FROM_KEYPAY:
          "/<%userArea%>/keypay/importPayCategoriesByBusinessUnitId/<%id%>",

        // Lookup Url
        BANKS_LOOKUP: "/<%userArea%>/banks",

        // General Amendment Url
        GENERAL_AMENDMENTS_URL: "/api/v2/amendments",
        GENERAL_AMENDMENTS_CREATE_URL: "/<%userArea%>/payruns/amendment/add",
        GENERAL_AMENDMENTS_BULK_CREATE_URL:
          "/<%userArea%>/payruns/amendment/bulkcreate",
        GENERAL_AMENDMENTS_DELETE_URL: "/<%userArea%>/payruns/amendment/delete",
        GENERAL_AMENDMENTS_DETAIL_URL: "/api/v2/amendments/<%id%>",
        GENERAL_AMENDMENTS_DELETION_URL: "/<%userArea%>/payruns/amendment/<%id%>",
        GENERAL_AMENDMENTS_UPDATE_URL: "/<%userArea%>/payruns/amendment/<%id%>/update",
        GENERAL_AMENDMENTS_HISTORY_URL:
          "/<%userArea%>/payruns/amendment/<%id%>/history",
        GENERAL_AMENDMENTS_UPLOAD_URL:
          "/<%userArea%>/Payruns/amendments/<%amendmentId%>/upload",
        GENERAL_AMENDMENTS_REJECT_URL:
          "/<%userArea%>/payruns/amendment/<%amendmentId%>/reject",
        GENERAL_AMENDMENTS_REQUESTED_BY_URL:
          "/<%userArea%>/lookups/amendmentsrequestedby",
        GENERAL_AMENDMENTS_UPDATE_TERMINATION: '/api/v2/amendments/termination/<%id%>',

        // Dashboard
        DASHBOARD_URL: "/api/dashboard",
        DASHBOARD_DETAIL_URL: "/api/dashboard/<%id%>",

        // Image upload url
        IMAGE_UPLOAD_URL: env.image_upload_url,

        // Setting
        PERSONA_SETTINGS_URL: "/api/persona/settings",
        ROLE_SETTINGS_URL: "/api/userrole/settings",
        PERSONA_SETTINGS_TEMPLATE_URL: "/api/role-template/<%id%>",

        //Integration Settings
        GET_INTEGRATION_SETTINGS:
          "/<%userArea%>/businessunits/getIntegrationSettings/<%id%>",
        SET_INTEGRATION_SETTINGS:
          "/<%userArea%>/businessunits/updateIntegrationSettings",
        SET_PSP_INTEGRATION_SETTINGS:
          "/<%userArea%>/payrollserviceproviders/updateIntegrationSettings",
        // Get url from BE for keypay reports iFrame
        GENERATE_KEYPAY_SSO_URL: "/api/keypay/GenerateSingleSignOnUrl/<%id%>",
      };
      ApiUrlHandler.instance = this;
    }

    return ApiUrlHandler.instance;
  }

  constructEndpoint({ endpoint, options = {} }: any) {
    options.userArea = getUserArea();
    const url = env.baseurl + templateEngine(this._urls[endpoint], options);
    return url;
  }
}

const instance = new ApiUrlHandler();
Object.freeze(instance);

export default instance;
