import { useQuery } from '@tanstack/react-query';

import { Result } from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { RoleAuthorisation } from './api-query.types';
import { ROLE_AUTHORISATION_MODULE_IDS } from './role-authorisation.constants';
import { ApiEndpoint, apiUrlBuilder } from './url.helper';

/** @deprecated use the same hook from @xemplo/role-query instead */
export const useRoleAuthorisation = (moduleId: ROLE_AUTHORISATION_MODULE_IDS) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(ApiEndpoint.RoleAuthorisation, applicationApiUrl, {
    id: moduleId,
  });

  const { data } = useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [url, applicationApiUrl, moduleId],
    queryFn: () => client.get<Result<RoleAuthorisation[]>>(url).then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!moduleId,
  });

  const componentVisible = (componentName: string) => {
    if (!data?.result) return false;
    const component = data.result?.find((c) => c.name === componentName);
    return component?.visible;
  };

  const componentDisabled = (componentName: string) => {
    if (!data?.result) return true;
    const component = data.result?.find((c) => c.name === componentName);
    return component?.disabled;
  };

  const getComponent = (componentName: string) => {
    if (!data?.result) return;
    return data.result?.find((c) => c.name === componentName);
  };

  return { data: data?.result, componentVisible, componentDisabled, getComponent };
};
