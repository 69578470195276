export const PayrollRoutes = {
  Home: 'home',
  Login: 'login',
  ConfirmLogin: 'confirm-login',
  Logout: 'logout',
  ConfirmLogout: 'confirm-logout',
  SelectRole: 'select-role',
  Company: 'company',
  BusinessUnit: 'businessunit',
  PayrollProvider: 'payrollprovider',
  BillingServiceProvider: 'billingserviceproviders',
  PaySchedules: 'payschedules',
  Contracts: 'contracts',
  Employees: 'employees',
  Timesheets: 'timesheets',
  Companies: 'companies',
  ToggleFeature: 'toggle-feature',
  Payrun: 'payrun',
  PayrunAmendments: 'amendments',
  PayrunFilterByActive: '/payrun/?filterby=active',
  PayrunFilterByCompleted: '/payrun/?filterby=complete',
  PayrunFilterBySystemGenerated: '/payrun/?filterby=systemgenerated',
  PayrunById: 'payrun/:id',
  WorkerById: 'worker/:workerId',
  WorkerVarianceByWorkerId: 'worker/:workerId/variance',
  WorkerDetailsByWorkerId: 'worker/:workerId/details',
  WorkerAmendmentByAmendmentId: 'worker/:workerId/amendment/:amendmentId',
  Changelog: 'changelog',
  AmendmentsScreen: 'generalAmendments',
  AmendmentsFilterByPayrun: '/generalAmendments/?filterby=payrun',
  AmendmentsFilterByPermanent: '/generalAmendments/?filterby=permanent',
  AmendmentsFilterByRecurring: '/generalAmendments/?filterby=recurring',
  UsersScreen: 'users',
};

export const PayrollDynamicRoutes = {
  PayrunById: (id: string | number) =>
    PayrollRoutes.PayrunById.replace(':id', id.toString()),
  WorkerById: (workerId: string | number) =>
    PayrollRoutes.WorkerById.replace(':workerId', workerId.toString()),
  WorkerDetailsByWorkerId: (workerId: string | number) =>
    PayrollRoutes.WorkerDetailsByWorkerId.replace(':workerId', workerId.toString()),
  WorkerVarianceByWorkerId: (workerId: string | number) =>
    PayrollRoutes.WorkerVarianceByWorkerId.replace(':workerId', workerId.toString()),
  WorkerAmendmentByAmendmentId: (
    workerId: string | number,
    amendmentId: string | number
  ) =>
    PayrollRoutes.WorkerAmendmentByAmendmentId.replace(
      ':workerId',
      workerId.toString()
    ).replace(':amendmentId', amendmentId.toString()),
  manageBusinessUnits: (pspId: string, userId: string) =>
    `/psp/${pspId}/user/${userId}/assignbusinessunits`,
};
