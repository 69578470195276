import { ReactNode } from 'react';

import { AmendmentChangeLog } from '@xemplo/amendment-change-log';
import { AmendmentFiles } from '@xemplo/amendment-files';
import {
  useGetAmendmentByIdQueryV2,
  useGetAmendmentFilesQuery,
} from '@xemplo/amendment-query';
import { formatToCurrency, tryJsonParse } from '@xemplo/common-util';
import {
  Amendment,
  AmendmentStatusIds,
  AmendmentTypes,
  ChangeTaskTypes,
  RejectionReason,
} from '@xemplo/gp-types';
import { getRequestedByUserName } from '@xemplo/gp-utils';
import { CenteredLoaderDots } from '@xemplo/loader';

import { amendmentDetailsModalTestIds } from '../../../use-amendment-deatils-modal';
import { formatDate } from '../../amendment-details-modal.helper';
import * as S from '../../amendment-details-modal-styles';

import { TerminationSummary } from './termination-summary/termination-summary';
import { AppliesTo } from './appliesTo';
import { RejectionInfo } from './modal-rejection-info';
import { PendingAmount } from './pending-amount';

export interface AmendmentDetailsModalBodyProps {
  amendment: Amendment;
}
export function AmendmentDetailsModalBody(
  props: Readonly<AmendmentDetailsModalBodyProps>
) {
  const { amendment } = props;

  const { data } = useGetAmendmentByIdQueryV2({ id: amendment.amendmentId });
  const { data: amendmentFiles } = useGetAmendmentFilesQuery({
    id: amendment.amendmentId,
  });
  if (!data) return <CenteredLoaderDots />;

  const {
    amendmentStatusId,
    amendmentStatusName,
    payrunName,
    amendmentTaskTypeId,
    amendmentTypeName,
    amendmentTaskTypeName,
    amendmentSubTypeName,
    amount,
    units,
    rejectDescription,
    requestedBy,
    recurringExpiryDate,
    note,
    amendmentSummary,
    lumpSumNumberOfPayPeriods,
    lumpSumCalculationMethod,
    amendmentTypeId,
    recurringMaximumAmount,
    assignTo,
    amendmentKeypayStatusId,
    isIntegrated,
  } = data.result;

  //TODO: Replace this with the value to be returned from BE https://expedo.atlassian.net/browse/GC-870
  const totalAmount = (units ?? 1) * (amount ?? 0);

  const getRecurringValue = () => {
    if (amendmentTypeId !== AmendmentTypes.RECURRING) {
      return null;
    }
    if (recurringMaximumAmount) {
      return formatToCurrency({ value: recurringMaximumAmount });
    }
    if (recurringExpiryDate) {
      return formatDate({ value: recurringExpiryDate });
    }
    return 'N/A';
  };
  const isRejected = amendmentStatusId === AmendmentStatusIds.REJECTED;
  const isPayrunAmendment = amendmentTypeId === AmendmentTypes.PAYRUN;
  const rejectionReason = rejectDescription
    ? tryJsonParse<RejectionReason>(rejectDescription)
    : undefined;
  const isPayInstruction = amendmentTaskTypeId === ChangeTaskTypes.PAY_INSTRUCTION;
  const isTermination = amendmentTaskTypeId === ChangeTaskTypes.TERMINATION;

  return (
    <div data-testid={amendmentDetailsModalTestIds.body}>
      <S.Status status={amendmentStatusId} statusName={amendmentStatusName} />

      <S.HeadingLevel2>{amendmentTypeName}</S.HeadingLevel2>

      <dl>
        {isPayrunAmendment && (
          <S.DLRow className="single-column">
            <S.DT>Pay run</S.DT>
            <S.DD>{payrunName ?? 'Unlinked Pay run'}</S.DD>
          </S.DLRow>
        )}

        <S.DLRow>
          <S.DT>Type</S.DT>
          <S.DD>{amendmentTaskTypeName}</S.DD>
          {conditionalValue({ label: 'Sub type', value: amendmentSubTypeName })}
        </S.DLRow>

        <AppliesTo assignTo={assignTo} />

        <S.DLRow className="single-column">
          <S.DT>Requested by</S.DT>
          <S.DD>{getRequestedByUserName(requestedBy)}</S.DD>
        </S.DLRow>

        {!isPayInstruction && !isTermination && (
          <>
            <S.DLRow>
              <S.DT>Rate</S.DT>
              <S.DD>
                <PendingAmount
                  value={amount}
                  isIntegrated={isIntegrated}
                  amendmentKeypayStatusId={amendmentKeypayStatusId}
                />
              </S.DD>
              {conditionalValue({ label: 'Unit/s', value: units })}
            </S.DLRow>

            <S.DLRow className="single-column">
              <S.DT>Total amount</S.DT>
              <S.DD>
                <PendingAmount
                  value={totalAmount}
                  isIntegrated={isIntegrated}
                  amendmentKeypayStatusId={amendmentKeypayStatusId}
                />
              </S.DD>
            </S.DLRow>
          </>
        )}
        {/** For Pay Instructions the amount value from the BE is actually the total amount, for all other amendments the amount value from the BE is the rate */}
        {isPayInstruction && !!amount && (
          <S.DLRow className="single-column">
            <S.DT>Total amount</S.DT>
            <S.DD>{formatToCurrency({ value: amount })}</S.DD>
          </S.DLRow>
        )}

        {lumpSumCalculationMethod && (
          <S.DLRow>
            <S.DT>Tax Calculation Method</S.DT>
            <S.DD>{lumpSumCalculationMethod}</S.DD>
            {conditionalValue({
              label: 'Pay Periods',
              value: lumpSumNumberOfPayPeriods,
            })}
          </S.DLRow>
        )}

        {getRecurringValue() && (
          <S.DLRow className="single-column">
            <S.DT>Expiry</S.DT>
            <S.DD>{getRecurringValue()}</S.DD>
          </S.DLRow>
        )}

        {/** Note */}
        {!!note && (
          <S.DLRow className="single-column">
            {conditionalValue({
              label: isPayInstruction ? 'Instructions' : 'Note',
              value: note,
              ddClass: 'add-prewrap',
            })}
          </S.DLRow>
        )}
        {/** Pay Instruction File */}
        {!!amendmentFiles?.result?.length && (
          <S.DLRow className="single-column">
            <S.DT>Attachment</S.DT>
            <AmendmentFiles amendmentId={amendment.amendmentId} />
          </S.DLRow>
        )}

        <TerminationSummary summary={amendmentSummary} />

        {isRejected && <RejectionInfo rejectionReason={rejectionReason} />}

        <S.Divider />
        <AmendmentChangeLog amendmentId={amendment.amendmentId} />
      </dl>
    </div>
  );
}

function conditionalValue({
  label,
  value,
  ddClass = '',
}: {
  label: string;
  value?: ReactNode;
  ddClass?: string;
}) {
  return value ? (
    <>
      <S.DT>{label}</S.DT>
      <S.DD className={ddClass}>{value}</S.DD>
    </>
  ) : (
    <>
      <S.DT></S.DT>
      <S.DD></S.DD>
    </>
  );
}
