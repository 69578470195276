import { cloneElement, forwardRef, PropsWithChildren, ReactElement } from 'react';

import { iconSizeMap, typeDefaultVariantMap, validButtonTypes } from './button.helpers';
import { StyledButton, StyledChildrenContainer } from './button.styles';
import { ButtonIconPosition, ButtonProps, ButtonSize } from './button.types';

export const Button = forwardRef(
  (props: PropsWithChildren<ButtonProps>, ref?: React.Ref<HTMLButtonElement>) => {
    const {
      ariaLabel,
      children,
      size = ButtonSize.Standard,
      disabled,
      icon,
      iconPosition = ButtonIconPosition.Leading,
      className,
      onClick,
      variant,
      type,
      underline,
      testId,
    } = props;

    //Check if button variant is valid. Must be within validButtonTypes
    if (variant && !validButtonTypes[type].includes(variant)) {
      throw new Error(
        `Invalid button variant: ${variant} for button type: ${type}. Possible variants are: ${validButtonTypes[type]}`
      );
    }

    const sizedIcon = icon
      ? cloneElement(icon as ReactElement, {
          width: iconSizeMap[size],
          height: iconSizeMap[size],
        })
      : null;

    return (
      <StyledButton
        ref={ref}
        aria-label={ariaLabel}
        data-size={size}
        disabled={disabled}
        onClick={onClick}
        className={className}
        data-type={type}
        data-variant={variant ?? typeDefaultVariantMap[type]}
        data-underline={underline}
        data-testid={testId}
      >
        {icon && iconPosition === ButtonIconPosition.Leading && sizedIcon}
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
        {icon && iconPosition === ButtonIconPosition.Trailing && sizedIcon}
      </StyledButton>
    );
  }
);

export default Button;
