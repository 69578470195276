import { Spin } from "antd";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "@xemplo/auth-provider";
import { FeatureProvider } from "@xemplo/feature-flag";
import { RootStyle } from "@xemplo/root-style";
import { XemploAppSettingsProvider } from "@xemplo/xemplo-app-settings";

import App from "./App";
import { featureMap } from "./constants";
import { AUTH_CONFIG } from "./constants/auth";
import environment from "./constants/environment";
import { handleReturnUrl } from "./libs/return-url.helper";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { SupportedApps } from "@xemplo/types";

// Load insight only in staging and production
if (!["dev", "qa"].includes(environment.instance)) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.insight_key,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

const script_chart = document.createElement(
  "script_chart"
) as HTMLScriptElement;
script_chart.src = "https://www.gstatic.com/charts/loader.js";
script_chart.async = true;
document.body.appendChild(script_chart);

// tinymce
const script_tinymce = document.createElement(
  "script_tinymce"
) as HTMLScriptElement;
script_tinymce.src =
  "https://cdn.tiny.cloud/1/zozyl0xsr52trib3dncl1q2jbaii3ww2bc90pv29suh366v7/tinymce/5/tinymce.min.js";
script_tinymce.async = true;
document.body.appendChild(script_tinymce);

const appSettingsConfig = {
  APP_CLIENT_ID: "expedo.globaldash",
  VITE_APP_NAME: SupportedApps.GP,
  VITE_CLOUD_ROLE: `gp-${environment.instance}`,
  VITE_DS_URL: environment.directory_service_url,
  VITE_EIMS_URL: environment.identity_baseurl,
  VITE_INSTRUMENTATION_KEY: environment.insight_key,
  VITE_RELEASE_CHANNEL: environment.instance,
  VITE_SENTRY_DSN: "",
  baseUrl: "",
  applicationApiUrl: environment.baseurl,
  startAppUrl: environment.start_app_url,
  dsUrl: environment.directory_service_url,
};

// This is needed to handle refreshes on the page whilst the router is not
// completely fixed. The current routing implementation is not retaining the url from the browser
// and combined with the login redirects it causes some funky behaviours.
handleReturnUrl();
const client = new QueryClient();
const persistor = persistStore(store);
const rootElement = createRoot(document.getElementById("root") as HTMLElement);
rootElement.render(
  <XemploAppSettingsProvider {...appSettingsConfig}>
    <FeatureProvider
      featureMap={featureMap}
      environment={environment.instance}
      appContext="gp"
    >
      <QueryClientProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={<Spin />} persistor={persistor}>
            <AuthProvider {...AUTH_CONFIG}>
              <RootStyle />
              <App />
            </AuthProvider>
          </PersistGate>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </FeatureProvider>
  </XemploAppSettingsProvider>
);
serviceWorker.unregister();
