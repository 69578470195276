/**
 * TODO: Need to find a better way to deal with the non-null-assertions (var!)
 * It is used to provide correct type inference for the queries as null
 * checks would change the return type of the queries.
 *
 * At the same time, the DS API should not be returning nulls either.
 */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  DS_URL_PREFIX,
  DsAPI,
  PagedRequest,
  useDirectoryServices,
} from '@xemplo/directory-services';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import {
  Company,
  Instance,
  Navigation,
  Persona,
  User,
  UserflowSignature,
} from '@xemplo/types';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { mapNavData } from './ds-query.helper';
import {
  BaseQueryProps,
  CommonQueryProps,
  QueryWithCodeProps,
  QueryWithIdProps,
  QueryWithIdsProps,
  QueryWithParamsProps,
} from './use-ds-query.types';

const defaultProps = <TData>() => ({} as CommonQueryProps<TData>);
const defaultPropsWithId = <TData>() => ({ id: undefined } as QueryWithIdProps<TData>);
const defaultPropsWithIds = <TData>() => ({ ids: undefined } as QueryWithIdsProps<TData>);
const defaultPropsWithCode = <TData>() =>
  ({ code: undefined } as QueryWithCodeProps<TData>);

export const useGetUserQuery = (props?: CommonQueryProps<User>) => {
  const { authToken, queryProps, requestProps } = props ?? defaultProps<User>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const enabled = useMemo(() => authToken != null, [authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<User>({
    ...mergedQueryProps,
    queryKey: [DsAPI.currentUser, enabled, requestProps],
    queryFn: () => directoryServices.getCurrentUser(requestProps).then((res) => res.data),
    enabled,
  });
};

export const useGetPersonasQuery = (
  props?: QueryWithParamsProps<Persona[], PagedRequest>
) => {
  const { authToken, queryProps, requestProps } = props ?? defaultProps<Persona[]>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const enabled = useMemo(() => authToken != null, [authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Persona[]>({
    ...mergedQueryProps,
    queryKey: [DsAPI.personas, requestProps, enabled],
    queryFn: () => directoryServices.getPersonas(requestProps).then((res) => res.data),
    enabled,
  });
};

export const useGetInstancesQuery = (
  props?: QueryWithParamsProps<Instance[], PagedRequest>
) => {
  const { authToken, queryProps, requestProps } = props ?? defaultProps<Instance[]>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const enabled = useMemo(() => authToken != null, [authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Instance[]>({
    ...mergedQueryProps,
    queryKey: [DsAPI.instances, enabled, requestProps],
    queryFn: () => directoryServices.getInstances(requestProps).then((res) => res.data),
    enabled,
  });
};

export const useGetInstanceByIdQuery = (props: QueryWithIdProps<Instance>) => {
  const { authToken, id, queryProps, requestProps } =
    props ?? defaultPropsWithId<Instance>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const enabled = useMemo(() => id != null && authToken != null, [id, authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Instance>({
    ...mergedQueryProps,
    queryKey: [DsAPI.instances, enabled, requestProps, id],
    queryFn: () =>
      directoryServices
        .getInstanceById({ ...requestProps, id: id! })
        .then((res) => res.data),
    enabled,
  });
};

export const useGetCompanyByIdQuery = (props: QueryWithIdProps<Company>) => {
  const { authToken, id, queryProps, requestProps } =
    props ?? defaultPropsWithId<Company>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };

  const enabled = useMemo(() => id != null && authToken != null, [id, authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Company>({
    ...mergedQueryProps,
    queryKey: [DsAPI.companies, id, requestProps, enabled],
    queryFn: () =>
      directoryServices
        .getCompanyById({ ...requestProps, id: id! })
        .then((res) => res.data),
    enabled,
  });
};

export const useGetCompaniesByInstanceIdQuery = (props: QueryWithIdProps<Company[]>) => {
  const { authToken, id, queryProps, requestProps } =
    props ?? defaultPropsWithId<Company[]>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };

  const enabled = useMemo(() => id != null && authToken != null, [id, authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Company[]>({
    ...mergedQueryProps,
    queryKey: [DsAPI.companiesByInstanceId, id, requestProps, enabled],
    queryFn: () =>
      directoryServices
        .getCompaniesByInstanceId({ ...requestProps, id: id! })
        .then((res) => res.data),
    enabled,
  });
};

export const useGetCompaniesQuery = (props: QueryWithIdsProps<Company[]>) => {
  const { authToken, ids, queryProps, requestProps } =
    props ?? defaultPropsWithIds<Company[]>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };

  const enabled = useMemo(() => ids != null && authToken != null, [ids, authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Company[]>({
    ...mergedQueryProps,
    queryKey: [DsAPI.companies, requestProps, enabled, ids],
    queryFn: () => directoryServices.getCompanies({ ...requestProps, ids: ids! }),
    enabled,
  });
};

export const useGetNavigationQuery = (props?: CommonQueryProps<Navigation>) => {
  const { authToken, queryProps, requestProps } = props ?? defaultProps<Navigation>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };

  const enabled = useMemo(() => authToken != null, [authToken]);
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<Navigation>({
    ...mergedQueryProps,
    queryKey: [DsAPI.navigation, requestProps, enabled],
    queryFn: () =>
      directoryServices
        .getNavigation(requestProps)
        .then((res) => res.data)
        .then(mapNavData),
    enabled,
  });
};

/**
 * This is a new version of the useGetNavigationQuery that uses the new http client.
 * NOTE: Only use this if the app is using the @xemplo/auth-provider.
 * Also, this no longer has the enabled flag based on AuthToken. Beware of the side effects.
 * As this will throw when the user is not authenticated.
 */
export const useGetNavigationQueryV2 = (props?: BaseQueryProps<Navigation>) => {
  const { queryProps, requestProps } = props ?? defaultProps<Navigation>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const { client } = useHttpClient();

  const { dsUrl } = useAppSettings();
  const url = `${dsUrl}/${DS_URL_PREFIX}${DsAPI.navigation}`;

  return useQuery<Navigation>({
    ...mergedQueryProps,
    queryKey: [url, requestProps],
    queryFn: ({ signal }) =>
      client
        .get(url, { ...requestProps, signal })
        .then((res) => res.data)
        .then(mapNavData),
  });
};

export const useGetCurrentUserDetailsQuery = (props?: BaseQueryProps<User>) => {
  const { queryProps, requestProps } = props ?? defaultProps<User>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const { client } = useHttpClient();

  const { dsUrl } = useAppSettings();
  const url = `${dsUrl}/${DS_URL_PREFIX}${DsAPI.currentUser}`;

  return useQuery<User>({
    ...mergedQueryProps,
    queryKey: [url, requestProps],
    queryFn: ({ signal }) =>
      client.get(url, { ...requestProps, signal }).then((res) => res.data),
  });
};

export const useGetUserflowSignatureQuery = (
  props?: QueryWithCodeProps<UserflowSignature>
) => {
  const { authToken, code, queryProps } =
    props ?? defaultPropsWithCode<UserflowSignature>();
  const mergedQueryProps = { ...queryProps, ...DEFAULT_QUERY_PROPS };
  const directoryServices = useDirectoryServices({ authToken });

  return useQuery<UserflowSignature>({
    ...mergedQueryProps,
    queryKey: [DsAPI.userflow, code],
    queryFn: () =>
      directoryServices
        .getUserflowSignature({ code: code ?? '' })
        .then((res) => res.data),
    enabled: code != null,
  });
};
