import { useMemo } from 'react';

import { CustomAxios, CustomAxiosOptions } from '@xemplo/custom-axios';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { DirectoryServices } from './directory-services';

export const useDirectoryServices = (config?: CustomAxiosOptions) => {
  const { dsUrl } = useAppSettings();
  if (!dsUrl) {
    throw new Error(
      'Either the Directory Services URL is not set or the Xemplo API Provider is not set up correctly.'
    );
  }

  return useMemo(() => {
    const client = new CustomAxios(config).client();
    return new DirectoryServices({ client, baseUrl: dsUrl });
  }, [config, dsUrl]);
};
