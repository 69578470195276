import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { requireAuth } from '@xemplo/auth-provider';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { PayrollRoutes } from '@xemplo/gp-routes';
import { withLazyComponent } from '@xemplo/lazy-component';
import { Payrun } from '@xemplo/payrun';
import { EarningLineDetails } from '@xemplo/payrun-earning-line-detail';
import { PayrunWorkerDetailRoute } from '@xemplo/payrun-worker-detail';

const PayrunList = lazy(() =>
  import('@xemplo/payrun-list').then((m) => ({ default: m.PayrunList }))
);
const PayrunWorkers = lazy(() =>
  import('@xemplo/payrun-workers').then((m) => ({ default: m.PayrunWorkers }))
);
const PayrunAmendments = lazy(() =>
  import('@xemplo/payrun-amendments').then((m) => ({ default: m.PayrunAmendments }))
);
const PayrunChangelog = lazy(() =>
  import('@xemplo/payrun-changelog').then((m) => ({ default: m.PayrunChangelog }))
);

export const PayrunRoutesFullScreen: RouteObject[] = [
  {
    path: PayrollRoutes.PayrunById,
    Component: requireAuth(Payrun),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        Component: requireAuth(withLazyComponent(PayrunWorkers, true)),
        children: [
          {
            path: PayrollRoutes.WorkerById,
            Component: EarningLineDetails,
          },
          {
            path: PayrollRoutes.WorkerVarianceByWorkerId,
            Component: EarningLineDetails,
          },
          {
            path: PayrollRoutes.WorkerDetailsByWorkerId,
            Component: PayrunWorkerDetailRoute,
          },
          {
            path: PayrollRoutes.WorkerAmendmentByAmendmentId,
            Component: PayrunWorkerDetailRoute,
          },
        ],
      },
      {
        path: PayrollRoutes.PayrunAmendments,
        Component: requireAuth(withLazyComponent(PayrunAmendments)),
      },
      {
        path: PayrollRoutes.Changelog,
        Component: requireAuth(withLazyComponent(PayrunChangelog)),
      },
    ],
  },
];

export const PayrunRouteWithTemplate: RouteObject[] = [
  {
    path: PayrollRoutes.Payrun,
    Component: requireAuth(withLazyComponent(PayrunList)),
    errorElement: <ErrorBoundary />,
  },
];
