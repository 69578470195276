import { ButtonSize, ButtonType, ButtonVariant } from './button.types';

export const iconSizeMap = {
  [ButtonSize.Small]: 16,
  [ButtonSize.Medium]: 20,
  [ButtonSize.Standard]: 20,
  [ButtonSize.Large]: 24,
};

export const typeDefaultVariantMap = {
  [ButtonType.Primary]: ButtonVariant.Light,
  [ButtonType.Secondary]: ButtonVariant.Dark,
  [ButtonType.Destructive]: ButtonVariant.Light,
  [ButtonType.Tertiary]: ButtonVariant.Blue,
};

export const validButtonTypes: Record<ButtonType, ButtonVariant[]> = {
  [ButtonType.Primary]: [ButtonVariant.Light],
  [ButtonType.Secondary]: [ButtonVariant.Light, ButtonVariant.Dark],
  [ButtonType.Tertiary]: Object.values(ButtonVariant),
  [ButtonType.Destructive]: [ButtonVariant.Light],
};

export const LoaderSizeMap = {
  [ButtonSize.Small]: 2,
  [ButtonSize.Medium]: 2.67,
  [ButtonSize.Standard]: 2.67,
  [ButtonSize.Large]: 4,
};
