import { css } from 'styled-components';

import { Colour, Shadow } from '@xemplo/style-constants';

import { HeaderIconMode } from './common-header-icon.types';

export const IconContainerCommonStyles = css`
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
`;

export const IconWrapperModeCommonStyles = css`
  &[data-mode=${HeaderIconMode.Admin}] {
    background: ${Colour.White[100]};
    ${Shadow.Medium2};
    color: ${Colour.Gray[800]};

    & path {
      fill: url(#primaryGradient);
    }
  }

  &[data-mode=${HeaderIconMode.Worker}] {
    background: linear-gradient(135deg, #3991ff 0%, #a48afb 50.15%);
    color: ${Colour.White[100]};
    ${Shadow.Medium2};
  }

  &[data-mode=${HeaderIconMode.Blue}] {
    background: ${Colour.Blue[50]};
    color: ${Colour.Blue[500]};
    ${Shadow.Medium2};
  }

  &[data-mode=${HeaderIconMode.Shadowed}] {
    border: 2px solid ${Colour.White[100]};
    border-radius: 50%;
    ${Shadow.Medium1};
  }
`;

export const IconCommonStyles = css`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  transition: all 0.2s ease-out;

  & > svg {
    width: 32px;
    height: 32px;
    transition: all 0.2s ease-out;
  }
`;
