import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioButtonSize } from '../v1/radio-button.types';

import * as S from './radio-button-v2.styles';
import { RadioButtonV2Props } from './radio-button-v2.types';

export function RadioButtonV2({
  checked,
  name,
  value,
  disabled,
  id,
  ariaLabel,
  size,
  rules,
  error,
}: Readonly<RadioButtonV2Props>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { register, getFieldState, watch, getValues } = useFormContext();
  // We don't want to add the "disabled" prop when registering
  // the component as it results in a bad setState error.
  // https://github.com/orgs/react-hook-form/discussions/10964#discussioncomment-9079342
  const field = register(name, { ...rules, value });
  const fieldValue = watch(name);
  const { invalid } = getFieldState(name);

  // This effect is responsible for syncing the checked state of the input
  // When resetting the form value programmatically. It compares the form value
  // with the radio button value and sets the checked attribute accordingly.
  useEffect(() => {
    if (!inputRef.current) return;
    const isChecked =
      fieldValue != null ? fieldValue.toString() === value.toString() : false;

    inputRef.current.checked = isChecked;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  return (
    <S.RadioButtonV2
      {...field}
      ref={(e) => {
        inputRef.current = e;
        field.ref(e);
      }}
      id={id}
      type="radio"
      value={value}
      aria-label={ariaLabel}
      data-size={size ?? RadioButtonSize.Medium}
      data-error={invalid || error}
      defaultChecked={!!checked}
      disabled={disabled}
    />
  );
}

export default RadioButtonV2;
