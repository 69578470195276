import { HTMLAttributes } from 'react';

export type ButtonProps = {
  id?: string;
  size?: ButtonSize;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  ariaLabel: string;
  onClick?: HTMLAttributes<HTMLButtonElement>['onClick'];
  className?: string;
  variant?: ButtonVariant;
  type: ButtonType;
  underline?: boolean;
  testId?: string;
};

export const ButtonType = {
  Primary: 'primary',
  Secondary: 'secondary',
  Destructive: 'destructive',
  Tertiary: 'tertiary',
} as const;
export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType];

export const ButtonVariant = {
  Dark: 'dark',
  Light: 'light',
  Blue: 'blue',
  Error: 'error',
} as const;
export type ButtonVariant = (typeof ButtonVariant)[keyof typeof ButtonVariant];

export type ButtonWithStateProps = ButtonProps & {
  /** Avoid using this. It was added as a solution for initial loading on stories */
  isLoading?: boolean;
  onClickAsync?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>;
};

export const ButtonSize = {
  Large: 'large',
  Standard: 'standard',
  Medium: 'medium',
  Small: 'small',
} as const;
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];

export const ButtonIconPosition = {
  Leading: 'leading',
  Trailing: 'trailing',
} as const;
export type ButtonIconPosition =
  (typeof ButtonIconPosition)[keyof typeof ButtonIconPosition];
