import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isCancel } from 'axios';

import { BUSINESS_UNIT_QUERY_KEYS, PAYRUN_QUERY_KEYS } from '@xemplo/gp-constants';
import {
  Amendment,
  BusinessUnit,
  GpEarningLineDetailVarianceResponse,
  GpWorker,
  GpWorkerDetail,
  LegacyPagedResult,
  ListPagedResult,
  PAYRUN_STATUS_IDS,
  PayrunChangeLogItem,
  PayrunDetail,
  PayrunEarningLine,
  PayrunFile,
  PayrunHeader,
  PayrunInvoices,
  PayrunMilestone,
  PayrunSummary,
  QueryProps,
  QueryPropsWithId,
  Result,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { BusinessUnitAPI, PayrunAPI } from './payrun-query.constants';
import {
  emptyPayrunSummaryResponse,
  emptyPayrunWorkerDetailsResponse,
  mapMilestoneSteps,
  parseEarningLineDetailsRows,
} from './payrun-query.helpers';
import {
  RejectEarningLinesPayload,
  UseApproveEarningLinesProps,
  UseRejectEarningLinesProps,
  UseSubmitAmendmentsProps,
} from './payrun-query.types';

/**
 * Gets all workers for a given payrun
 */
export const useGetPayrunWorkersQuery = (
  props: QueryPropsWithId<ListPagedResult<GpWorker>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id, queryProps } = props;

  const url = apiUrlBuilder(
    PayrunAPI.workersByPayrunId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery<ListPagedResult<GpWorker>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS, url, requestParams],
    queryFn: () => client.get<ListPagedResult<GpWorker>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunWorkerDetailsQuery = (
  props: QueryPropsWithId<Result<GpWorkerDetail>> & { workerId: string }
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { workerId, id } = props;

  const url = apiUrlBuilder(PayrunAPI.workerDetailsById(id, workerId), applicationApiUrl);

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKER_DETAILS, url],
    queryFn: ({ signal }) => {
      return client
        .get<Result<GpWorkerDetail>>(url, { signal })
        .then((resp) => resp.data)
        .catch((err) => {
          if (isCancel(err)) {
            return emptyPayrunWorkerDetailsResponse;
          }
          throw err;
        });
    },
    enabled: !!(id && applicationApiUrl),
  });
};
/**
 * Gets all amendments for a given payrun
 */
export const useGetPayrunAmendmentsQuery = (
  props: QueryPropsWithId<ListPagedResult<Amendment>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id, queryProps } = props;

  const url = apiUrlBuilder(
    PayrunAPI.amendmentsByPayrunId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery<ListPagedResult<Amendment>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS, url, requestParams],
    queryFn: () => client.get<ListPagedResult<Amendment>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

/**
 * Gets payrun details for a given payrun
 */
export const useGetPayrunByIdQuery = (
  props: QueryPropsWithId<LegacyPagedResult<PayrunDetail>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id, queryProps } = props;
  const url = apiUrlBuilder(PayrunAPI.payrunById(id), applicationApiUrl, requestParams);

  return useQuery<LegacyPagedResult<PayrunDetail>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_DETAIL, url, requestParams],
    queryFn: () =>
      client.get<LegacyPagedResult<PayrunDetail>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunFilesQuery = (
  props: QueryPropsWithId<Result<PayrunFile>>,
  payrunStatusId: number | null
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id } = props;
  const url = apiUrlBuilder(
    PayrunAPI.filesByPayrunId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_FILES, url, requestParams],
    queryFn: () => client.get<Result<PayrunFile[]>>(url).then((resp) => resp.data),
    enabled: !!(
      id &&
      applicationApiUrl &&
      payrunStatusId &&
      payrunStatusId > PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED
    ),
  });
};

export const useGetPayrunMilestonesQuery = (
  props: QueryPropsWithId<Result<PayrunMilestone>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id } = props;
  const url = apiUrlBuilder(
    PayrunAPI.milesontesByPayrunId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_MILESTONES, url, requestParams],
    queryFn: () => client.get<Result<PayrunMilestone>>(url).then((resp) => resp.data),
    enabled: !!id && !!applicationApiUrl,
    select: (data) => mapMilestoneSteps(data.result),
  });
};

export const useGetPayrunHeaderQuery = (
  props: QueryPropsWithId<Result<PayrunHeader>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { id } = props;
  const url = apiUrlBuilder(PayrunAPI.headerByPayrunId(id), applicationApiUrl);

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_HEADER, url],
    queryFn: () => client.get<Result<PayrunHeader>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunChangeLogQuery = (
  props: QueryPropsWithId<ListPagedResult<PayrunChangeLogItem>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id } = props;
  const url = apiUrlBuilder(
    PayrunAPI.changeLogByPayrunId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_CHANGE_LOG, url, requestParams],
    queryFn: () =>
      client.get<ListPagedResult<PayrunChangeLogItem>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunEarningLinesQuery = (
  props: QueryPropsWithId<ListPagedResult<PayrunEarningLine>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, id, queryProps } = props;
  const url = apiUrlBuilder(
    PayrunAPI.earningLinesByPayrunId(id),
    applicationApiUrl,
    requestParams
  );
  return useQuery<ListPagedResult<PayrunEarningLine>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_EARNING_LINES, url, requestParams],
    queryFn: () =>
      client.get<ListPagedResult<PayrunEarningLine>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunEarningLineDetailsVarianceQuery = (
  props: QueryPropsWithId<Result<GpEarningLineDetailVarianceResponse>> & {
    workerId: string | null;
  }
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { id, workerId } = props;
  const url = apiUrlBuilder(
    PayrunAPI.earningLineDetailsVarianceById(id, workerId),
    applicationApiUrl
  );
  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_EARNING_LINE_DETAILS_VARIANCE, url],
    queryFn: () =>
      client
        .get<Result<GpEarningLineDetailVarianceResponse>>(url)
        .then((resp) => resp.data),
    enabled: !!id && !!applicationApiUrl && !!workerId,
    select: (data) => ({
      ...data,
      result: {
        ...data.result,
        parsedRows: parseEarningLineDetailsRows(
          data.result?.payrunEmployeeEarninglineItems
        ),
      },
    }),
  });
};

export const useGetPayrunSummaryQuery = (
  props: QueryPropsWithId<Result<PayrunSummary>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { id } = props;

  const url = apiUrlBuilder(PayrunAPI.summaryByPayrunId(id), applicationApiUrl);

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_SUMMARY, url],
    queryFn: ({ signal }) => {
      return client
        .get<Result<PayrunSummary>>(url, { signal })
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          if (isCancel(err)) {
            return emptyPayrunSummaryResponse;
          }
          throw err;
        });
    },
    enabled: !!(id && applicationApiUrl),
  });
};
export const useGetPayrunInvoicesQuery = (
  props: QueryPropsWithId<Result<PayrunInvoices>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { id } = props;

  const url = apiUrlBuilder(PayrunAPI.invoiceByPayrunId(id), applicationApiUrl);

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_INVOICE, url],
    queryFn: () => client.get<Result<PayrunInvoices>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetBusinessUnitByIdQuery = (
  props: QueryPropsWithId<Result<BusinessUnit>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { id } = props;

  const url = apiUrlBuilder(BusinessUnitAPI.businessUnitById(id), applicationApiUrl);

  return useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [BUSINESS_UNIT_QUERY_KEYS.GET_BUSINESS_UNIT, url],
    queryFn: () => client.get<Result<BusinessUnit>>(url).then((resp) => resp.data),
    enabled: !!(id && applicationApiUrl),
  });
};

export const useGetPayrunListQuery = (
  props: QueryProps<ListPagedResult<PayrunDetail>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(PayrunAPI.payruns, applicationApiUrl, requestParams);

  return useQuery<ListPagedResult<PayrunDetail>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_LIST, url, requestParams],
    queryFn: ({ signal }) =>
      client
        .get<ListPagedResult<PayrunDetail>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
};

/**
 * Hook submitting all amendments for a payrun in amendment stage. Progresses the payrun to the next stage.
 * @param props UseSubmitAmendmentsProps
 * @returns useMutation hook (Tanstack query) for submitting the post request
 */
export const useSubmitAmendments = ({
  onSuccess,
  onError,
  payrunId,
}: UseSubmitAmendmentsProps) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(
    PayrunAPI.submitAmendmentsByPayrunId(payrunId),
    applicationApiUrl
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => client.post(url).then((resp) => resp.data),
    onSuccess: (data: unknown, variables: unknown, context: unknown) => {
      onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_DETAIL] });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_CHANGE_LOG],
      });
    },
    onError,
  });
};

/** Approve payrun during review stage  */
export const useApproveEarningLines = ({
  onSuccess,
  onError,
  payrunId,
}: UseApproveEarningLinesProps) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(
    PayrunAPI.approveEarningLinesByPayrunId(payrunId),
    applicationApiUrl
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => client.post(url).then((resp) => resp.data),
    onSuccess: (data: unknown, variables: unknown, context: unknown) => {
      onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_DETAIL] });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_CHANGE_LOG],
      });
    },
    onError,
  });
};

/** Reject payrun during review stage  */
export const useRejectEarningLines = ({
  onSuccess,
  onError,
  payrunId,
}: UseRejectEarningLinesProps) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(
    PayrunAPI.rejectEarningLinesByPayrunId(payrunId),
    applicationApiUrl
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: RejectEarningLinesPayload) =>
      client.post(url, payload).then((resp) => resp.data),
    onSuccess: (data: unknown, variables: unknown, context: unknown) => {
      onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_DETAIL] });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_CHANGE_LOG],
      });
    },
    onError,
  });
};

/** Mark invoice as paid */
export const usePayInvoice = ({
  onSuccess,
  onError,
  payrunId,
}: UseRejectEarningLinesProps) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(PayrunAPI.payInvoiceByPayrunId(payrunId), applicationApiUrl);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => client.post(url).then((resp) => resp.data),
    onSuccess: (data: unknown, variables: unknown, context: unknown) => {
      onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_DETAIL] });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_CHANGE_LOG],
      });
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_INVOICE] });
    },
    onError,
  });
};
