import styled, { css } from 'styled-components';

import { Colour } from '@xemplo/style-constants';

const IconButtonBaseStyles = css<{ $size: number[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  font-size: ${({ $size }) => $size[0]}px;
  width: ${({ $size }) => $size[1]}px;
  height: ${({ $size }) => $size[1]}px;
  color: ${Colour.Gray[400]};
  background: transparent;
  border: 1px solid ${Colour.Gray[200]};
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.1s ease-out;

  &:hover:not([disabled]) {
    background: ${Colour.Black[5]};
    border-color: ${Colour.Gray[300]};
    color: ${Colour.Gray[600]};

    &.naked {
      background: ${Colour.Black[5]};
    }

    &.darker {
      background: ${Colour.Black[10]};
      border-color: ${Colour.Gray[400]};
      color: ${Colour.Gray[800]};
    }
    &.lighter {
      background: ${Colour.White[10]};
      border-color: ${Colour.White[100]};
      color: ${Colour.White[100]};
    }
    &.primary {
      border: none;
      background: ${Colour.Blue[600]};
      color: ${Colour.White[100]};
    }
  }

  &[disabled] {
    cursor: initial;
    opacity: 0.3;
  }

  &:focus,
  :focus-within {
    outline: none;
  }
`;

export const IconButton = styled.button<{
  $size: number[];
}>`
  ${IconButtonBaseStyles};

  &.darker {
    border: 1px solid ${Colour.Gray[300]};
    color: ${Colour.Gray[500]};
    background: transparent;
  }

  &.lighter {
    border: 1px solid ${Colour.Gray[200]};
    color: ${Colour.White[100]};
    background: transparent;
  }

  &.primary {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
    border: none;
  }

  &.naked {
    background: transparent;
    border: none;

    &.primary {
      color: ${Colour.Blue[500]};
      background: transparent;
    }
  }
`;
