import { useQuery } from '@tanstack/react-query';

import { AMENDMENT_QUERY_KEYS } from '@xemplo/gp-constants';
import {
  Amendment,
  ListPagedResult,
  QueryProps,
  QueryPropsWithId,
  Result,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { AmendmentAPIV2 } from './amendment-query.constants';
import { GetAmendmentsQueryStringV2 } from './amendment-query.types';

export const useGetAmendmentsQueryV2 = (
  props: QueryProps<ListPagedResult<Amendment>, GetAmendmentsQueryStringV2>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(
    AmendmentAPIV2.getAmendments,
    applicationApiUrl,
    requestParams
  );

  return useQuery<ListPagedResult<Amendment>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS, url],
    queryFn: ({ signal }) =>
      client.get<ListPagedResult<Amendment>>(url, { signal }).then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
};

export const useGetAmendmentByIdQueryV2 = (
  props: QueryPropsWithId<Result<Amendment>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { queryProps, id } = props;
  const url = apiUrlBuilder(AmendmentAPIV2.getAmendmentById(id), applicationApiUrl);

  return useQuery<Result<Amendment>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID, url],
    queryFn: ({ signal }) =>
      client.get<Result<Amendment>>(url, { signal }).then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};
