import { PendingAmount } from '@xemplo/amendment-details-modal';
import { AmendmentFiles } from '@xemplo/amendment-files';
import { useGetAmendmentFilesQuery } from '@xemplo/amendment-query';
import { AmendmentStatus } from '@xemplo/amendment-status';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { getAssignedWorker, getAssignedWorkerDisplayName } from '@xemplo/gp-utils';
import { Tooltip, TooltipPlacement } from '@xemplo/tooltip';

import * as S from './payrun-amendment-detail.styles';
import { PayrunAmendmentDetailProps } from './payrun-amendment-detail.types';
import { RejectReason } from './reject-reason';

export function PayrunAmendmentDetail(props: Readonly<PayrunAmendmentDetailProps>) {
  const {
    amendmentSubTypeName,
    amount,
    amendmentTypeName,
    amendmentTaskTypeName,
    recurringExpiryDate,
    amendmentStatusName,
    amendmentStatusId,
    amendmentStatusID,
    assignTo,
    amendmentTaskTypeId,
    amendmentId,
    note,
    units,
    isIntegrated,
    amendmentKeypayStatusId,
  } = props.amendment;

  const requestedBy = JSON.parse(props.amendment.requestedBy ?? '');
  const assignedWorkersString = getAssignedWorker(assignTo)
    ?.map((worker) => `${worker.firstName} ${worker.lastName}`)
    .join(', ');
  const { data: amendmentFiles } = useGetAmendmentFilesQuery({
    id: amendmentId,
  });

  const totalAmount = (amount ?? 0) * (units ?? 1);
  return (
    <S.DetailContainer>
      <RejectReason amendment={props.amendment} />
      <S.SubType>{amendmentSubTypeName}</S.SubType>
      <S.DetailRow>
        <S.DetailLabel> Amendment Type</S.DetailLabel>
        <S.DetailValue>{amendmentTypeName}</S.DetailValue>
      </S.DetailRow>
      <S.DetailRow>
        <S.DetailLabel> Type </S.DetailLabel>
        <S.DetailValue>{amendmentTaskTypeName}</S.DetailValue>
      </S.DetailRow>
      <S.DetailRow>
        <S.DetailLabel> Applies To</S.DetailLabel>
        <S.DetailValue>
          <Tooltip
            text={
              assignedWorkersString
                ? assignedWorkersString
                : getAssignedWorkerDisplayName(assignTo)
            }
            placement={TooltipPlacement.BottomLeft}
          >
            {getAssignedWorkerDisplayName(assignTo)}
          </Tooltip>
        </S.DetailValue>
      </S.DetailRow>

      <S.DetailRow>
        <S.DetailLabel> Requested by</S.DetailLabel>
        <S.DetailValue> {requestedBy?.UserName}</S.DetailValue>
      </S.DetailRow>
      <S.DetailRow>
        <S.DetailLabel>Expiry</S.DetailLabel>
        <S.DetailValue>{recurringExpiryDate?.toString() ?? 'N/A'}</S.DetailValue>
      </S.DetailRow>
      <S.DetailRow>
        <S.DetailLabel> Status</S.DetailLabel>
        <S.DetailValue>
          <AmendmentStatus
            status={amendmentStatusId ?? amendmentStatusID}
            statusName={amendmentStatusName}
          />
        </S.DetailValue>
      </S.DetailRow>
      <S.DetailRow>
        <S.DetailLabel>Rate</S.DetailLabel>
        <S.DetailValue>
          <PendingAmount
            value={amount}
            isIntegrated={isIntegrated}
            amendmentKeypayStatusId={amendmentKeypayStatusId}
            tooltipPosition={TooltipPlacement.BottomLeft}
          />
        </S.DetailValue>
      </S.DetailRow>
      {amendmentTaskTypeId !== ChangeTaskTypes.PAY_INSTRUCTION && (
        <>
          <S.DetailRow>
            <S.DetailLabel>Units</S.DetailLabel>
            <S.DetailValue>{units}</S.DetailValue>
          </S.DetailRow>

          <S.TotalAmount>
            <span>Total Amount</span>
            <S.DetailValue>
              <PendingAmount
                value={totalAmount}
                isIntegrated={isIntegrated}
                amendmentKeypayStatusId={amendmentKeypayStatusId}
                tooltipPosition={TooltipPlacement.BottomLeft}
              />
            </S.DetailValue>
          </S.TotalAmount>
        </>
      )}

      {!!note?.length && (
        <S.LargeDetailRow>
          <S.DetailLabel>
            {amendmentTaskTypeId === ChangeTaskTypes.PAY_INSTRUCTION
              ? 'Instruction'
              : 'Note'}
          </S.DetailLabel>
          <S.DetailValue>{note}</S.DetailValue>
        </S.LargeDetailRow>
      )}
      {!!amendmentFiles?.result?.length && (
        <S.LargeDetailRow>
          <S.DetailLabel>Attachment</S.DetailLabel>
          <AmendmentFiles amendmentId={amendmentId} />
        </S.LargeDetailRow>
      )}
    </S.DetailContainer>
  );
}
