import { HTMLAttributes } from 'react';

export interface IconButtonProps {
  id: string;
  size?: IconButtonSize;
  naked?: boolean;
  variant?: IconButtonVariant;
  className?: string;
  disabled?: boolean;
  /** The icon name is used as ARIA-LABEL for accessibility, give a sensible name for it */
  ariaLabel: string;
  onClick?: HTMLAttributes<HTMLButtonElement>['onClick'];
}

export const IconButtonSize = {
  XSmall: 'xsmall',
  Small: 'small',
  Medium: 'medium',
  Standard: 'standard',
  Large: 'large',
} as const;

export type IconButtonSize = (typeof IconButtonSize)[keyof typeof IconButtonSize];

export const IconButtonVariant = {
  Darker: 'darker',
  Lighter: 'lighter',
  Default: 'default',
  Primary: 'primary',
} as const;

export type IconButtonVariant =
  (typeof IconButtonVariant)[keyof typeof IconButtonVariant];
