import { useQuery } from '@tanstack/react-query';

import { QueryProps, QueryPropsWithId } from '@xemplo/gp-api';
import {
  BusinessUnitAssignedUserLoookup,
  BusinessUnitWorker,
  CompanyLookup,
  LegacyListPagedResult,
  ListPagedResult,
  Result,
  TerminatedWorker,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

import { LookupsAPI } from './lookups-query.constants';

export const useGetBusinessUnitWorkersQuery = (
  props: QueryPropsWithId<LegacyListPagedResult<BusinessUnitWorker>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps, id } = props;
  const url = apiUrlBuilder(
    LookupsAPI.workersByBusinessUnitId(id),
    applicationApiUrl,
    requestParams
  );

  return useQuery<LegacyListPagedResult<BusinessUnitWorker>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: () =>
      client
        .get<LegacyListPagedResult<BusinessUnitWorker>>(url)
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};

export const useGetTerminatedBusinessUnitWorkersQuery = (
  props: QueryPropsWithId<ListPagedResult<TerminatedWorker>>
) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps, id } = props;
  const url = apiUrlBuilder(
    LookupsAPI.terminatedWorkersByBusinessUnitId(id),
    applicationApiUrl,
    requestParams
  );
  return useQuery<ListPagedResult<TerminatedWorker>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: ({ signal }) =>
      client
        .get<ListPagedResult<TerminatedWorker>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};

export function useGetCompaniesQuery(props: QueryProps<ListPagedResult<CompanyLookup>>) {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(LookupsAPI.companies, applicationApiUrl, requestParams);

  return useQuery<ListPagedResult<CompanyLookup>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: ({ signal }) =>
      client
        .get<ListPagedResult<CompanyLookup>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
}

export function useGetBusinessUnitAssignedUsersQuery(
  props: QueryProps<Result<BusinessUnitAssignedUserLoookup[]>>
) {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(
    LookupsAPI.businessUnitAssignedUsers,
    applicationApiUrl,
    requestParams
  );

  return useQuery<Result<BusinessUnitAssignedUserLoookup[]>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: ({ signal }) =>
      client
        .get<Result<BusinessUnitAssignedUserLoookup[]>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
}
