import { useQuery } from '@tanstack/react-query';

import { RoleEndpoint } from '@xemplo/gp-constants';
import { PersonaRole, Result } from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { AuthorizationModule, ModuleAuthorisation } from '@xemplo/role-types';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useAppSettings } from '@xemplo/xemplo-app-settings';

export const useRoleAuthorisation = (moduleId?: AuthorizationModule) => {
  const { applicationApiUrl } = useAppSettings();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(
    RoleEndpoint.authorization,
    applicationApiUrl,
    moduleId && {
      ids: moduleId,
    }
  );

  const query = useQuery({
    ...DEFAULT_QUERY_PROPS,
    queryKey: [url, applicationApiUrl, moduleId],
    queryFn: () => client.get<Result<ModuleAuthorisation>>(url).then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });

  const { data } = query;

  const componentVisible = (componentName: string) => {
    if (!data?.result?.auth?.length) return false;

    const { auth } = data.result;
    const component = auth.find((c) => c.name === componentName);
    return !!component?.visible;
  };

  const componentDisabled = (componentName: string) => {
    if (!data?.result?.auth?.length) return true;

    const { auth } = data.result;
    const component = auth.find((c) => c.name === componentName);
    return !!component?.disabled;
  };

  const getComponent = (componentName: string) => {
    if (!data?.result?.auth?.length) return null;
    const { auth } = data.result;
    return auth.find((c) => c.name === componentName);
  };

  const isInRole = (roles: PersonaRole[]) => {
    if (!data?.result?.roleName) return false;
    return roles.includes(data.result.roleName);
  };

  return {
    ...query,
    componentVisible,
    componentDisabled,
    getComponent,
    isInRole,
  };
};
